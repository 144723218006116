// import { ReactComponent as CategoryICON } from '../../assets/images/search/categories.svg';
// import { ReactComponent as BrandICON } from '../../assets/images/search/categories.svg';
import { CATEGORY, BRAND, KEYWORD } from '../../global/svgList';

const searchElements = {
    category: {
        label: 'Category',
        elementType: 'select',
        placeholder: 'Category',
        validation: {
            required: false,
        },
        valid: true,
        value: '',
        svg: CATEGORY,
        className: 'search-category',
    },
    brand: {
        label: 'Brand',
        elementType: 'select',
        placeholder: 'Brand',
        validation: {
            required: false,
        },
        valid: true,
        value: '',
        svg: BRAND,
        className: 'search-brand',
    },
    keyword: {
        label: 'Keyword',
        elementType: 'input',
        placeholder: 'Keyword',
        validation: {
            required: true,
        },
        valid: true,
        value: '',
        svg: KEYWORD,
        className: 'search-keyword',
    },
};

export default searchElements;
