import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
// ui
import Service from '../mobile/mobService';
// images
import twentyFourImg from '../../assets/images/services/twentyfour.png';
import freeDeliveryImg from '../../assets/images/services/freedelivery.png';
import customerCareImg from '../../assets/images/services/customer.png';
import ribbonImg from '../../assets/images/services/ribbon.png';
import ribbonInvertedImg from '../../assets/images/services/ribboninverted.png';

const serviceArray = [
    {
        image: customerCareImg,
        heading: 'CUSTOMER SUPPORT',
        text:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    },
    {
        image: freeDeliveryImg,
        heading: 'FREE DELIVERY',
        text:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    },
    {
        image: twentyFourImg,
        heading: '24x7 SUPPORT',
        text:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    },
];

const MainContainer = styled.div`
    width: 100%;
    text-align: center;
    margin-top: 20px;
    .cat-topic {
        display: inline-block;
        margin: auto;
        p {
            font-family: 'Titillium Web', sans-serif;
            font-weight: 700;
            font-size: 0.9rem;
            margin: 0;
        }
        div {
            height: 2px;
            width: 90%;
            margin: auto;
            background-color: #a5365c;
        }
    }
    @media (min-width: 940px) {
        margin-top: 38px;
        .cat-topic {
            p {
                font-size: 1.4rem;
            }
            div {
                height: 4px;
            }
        }
    }
    @media (min-width: 1240px) {
        margin-top: 38px;
        .cat-topic {
            p {
                font-size: 1.8rem;
            }
        }
    }
`;

const ServicesContainer = styled.div`
    margin: 18px auto 0 auto;
    .ribbon-container {
        width: 70%;
        margin: auto;
        & img {
            height: 40px;
            width: 100%;
        }
    }
    @media (min-width: 699px) {
        width: 100%;
        display: flex;
        justify-content: center;
        background-color: #ecedee;
    }
    @media (min-width: 940px) {
        grid-gap: 35px;
        margin-top: 23px;
    }
`;

let servicesContainer = null;

const services = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const updateWindowDimensions = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', updateWindowDimensions);
        return () => {
            window.removeEventListener('resize', updateWindowDimensions);
        };
    });

    if (windowWidth > 699) {
        servicesContainer = (
            <ServicesContainer>
                <Service
                    cssClass="service-1"
                    {...serviceArray[0]}
                    toggle={false}
                />
                <Service
                    cssClass="service-2"
                    {...serviceArray[1]}
                    toggle={false}
                />
                <Service
                    cssClass="service-3"
                    {...serviceArray[2]}
                    toggled={false}
                />
            </ServicesContainer>
        );
    } else {
        servicesContainer = (
            <ServicesContainer>
                <Service
                    cssClass="service-1"
                    {...serviceArray[0]}
                    toggle={false}
                />
                <div className="ribbon-container">
                    <img src={ribbonImg} alt="ribbon" />
                </div>
                <Service cssClass="service-2" {...serviceArray[1]} toggled />
                <div className="ribbon-container">
                    <img src={ribbonInvertedImg} alt="ribbon" />
                </div>
                <Service
                    cssClass="service-3"
                    {...serviceArray[2]}
                    toggled={false}
                />
            </ServicesContainer>
        );
    }

    return (
        <MainContainer>
            <div className="cat-topic">
                <p>OUR SERVICES</p>
                <div />
            </div>
            {servicesContainer}
        </MainContainer>
    );
};

export default services;
