import React from 'react';
import styled from 'styled-components';
// ui
import Category from './category';
// images
import homeAndOfficeImg from '../../assets/images/categories/homeandoffice.png';
import electronicImg from '../../assets/images/categories/electronic.png';
import officeImg from '../../assets/images/categories/officeauto.png';
import powerToolsImg from '../../assets/images/categories/powertools.png';
import sportImg from '../../assets/images/categories/sport.png';
import musicalImg from '../../assets/images/categories/musical.png';
import categoryBackgroundImg from '../../assets/images/categories/back.png';

const categoryList = [
    {
        type: 'Home and Office Furniture',
        image: homeAndOfficeImg,
        desc: '',
    },
    {
        type: 'Electronic Appliances',
        image: electronicImg,
        desc: '',
    },
    {
        type: 'Office Automation',
        image: officeImg,
        desc: '',
    },
    {
        type: 'Power Tools',
        image: powerToolsImg,
        desc: '',
    },
    {
        type: 'Sport Items',
        image: sportImg,
        desc: '',
    },
    {
        type: 'Musical Instruments',
        image: musicalImg,
        desc: '',
    },
];

const MainContainer = styled.div`
    width: 100%;
    text-align: center;
    margin-top: 20px;
    .cat-topic {
        display: inline-block;
        margin: auto;
        p {
            font-family: 'Titillium Web', sans-serif;
            font-weight: 700;
            font-size: 0.9rem;
            margin: 0;
        }
        div {
            height: 2px;
            width: 90%;
            margin: auto;
            background-color: #a5365c;
        }
    }
    @media (min-width: 940px) {
        margin-top: 30px;
        .cat-topic {
            p {
                font-size: 1.4rem;
            }
            div {
                height: 4px;
            }
        }
    }
    @media (min-width: 1240px) {
        margin-top: 30px;
        .cat-topic {
            p {
                font-size: 1.8rem;
            }
        }
    }
`;

const CategoryContainer = styled.div`
    margin: 18px auto 0 auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    background: url(${categoryBackgroundImg});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    @media (min-width: 699px) {
        width: 100%;
        padding: 0 80px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 15px;
    }
    @media (min-width: 940px) {
        grid-gap: 35px;
        margin-top: 23px;
    }
    @media (min-width: 1240px) {
        width: 100%;
        padding: 0 80px;
        display: grid;
        grid-template-columns: repeat(3, 350px);
        grid-gap: 50px;
    }
`;

const categories = () => (
    <MainContainer>
        <div className="cat-topic">
            <p>PRODUCT CATEGORIES</p>
            <div />
        </div>
        <CategoryContainer>
            {categoryList.map(element => (
                <Category key={element.type} productCategory={element} />
            ))}
        </CategoryContainer>
    </MainContainer>
);

export default categories;
