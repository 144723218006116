import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
    .cls-1 {
        fill: none;
    }
    .cls-1,
    .cls-2,
    .cls-3,
    .cls-4 {
        stroke: ${props => props.color};
        stroke-miterlimit: 10;
    }
    .cls-1,
    .cls-4 {
        stroke-width: ${props => props.stroke};
    }
    .cls-2,
    .cls-4 {
        fill: ${props => props.color};
    }
    .cls-3 {
        fill: #231f20;
    }
`;

const category = ({
    style = {},
    width = '100%',
    height = '100%',
    viewBox = '0 0 517.46 506.88',
    color = '#000',
    stroke = '15px',
}) => {
    return (
        <SVG
            color={color}
            style={style}
            width={width}
            height={height}
            viewBox={viewBox}
            stroke={stroke}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path
                className="cls-1"
                d="M142.08,6H377.76a2.39,2.39,0,0,1,2.38,2.38V47a2,2,0,0,1-2,2h-236a2,2,0,0,1-2-2V7.94A1.94,1.94,0,0,1,142.08,6Z"
            />
            <path
                className="cls-1"
                d="M300.14,125H142.66a2.52,2.52,0,0,1-2.52-2.52V83.6a2.59,2.59,0,0,1,2.6-2.6H380a.16.16,0,0,1,.16.16v42.68A1.16,1.16,0,0,1,379,125H338.14"
            />
            <path
                className="cls-1"
                d="M143.34,159H377.58a2.56,2.56,0,0,1,2.56,2.56v37.36a2.08,2.08,0,0,1-2.08,2.08H141.78a1.63,1.63,0,0,1-1.64-1.64V162.2A3.2,3.2,0,0,1,143.34,159Z"
            />
            <line className="cls-1" x1="152.14" y1="49" x2="152.14" y2="81" />
            <line className="cls-1" x1="152.14" y1="125" x2="152.14" y2="159" />
            <line className="cls-1" x1="368.14" y1="49" x2="368.14" y2="81" />
            <line className="cls-1" x1="368.14" y1="125" x2="368.14" y2="159" />
            <circle className="cls-2" cx="301.64" cy="125" r="6.19" />
            <circle className="cls-3" cx="118.22" cy="280.92" r="6.19" />
            <circle className="cls-3" cx="118.22" cy="280.92" r="6.19" />
            <circle className="cls-2" cx="118.22" cy="280.92" r="6.19" />
            <circle className="cls-2" cx="260.14" cy="280.92" r="6.19" />
            <circle className="cls-2" cx="400.46" cy="280.92" r="6.19" />
            <circle className="cls-2" cx="338.39" cy="125" r="6.19" />
            <line className="cls-1" x1="260.14" y1="201" x2="260.14" y2="248" />
            <path
                className="cls-1"
                d="M473.14,297V252.56a4.56,4.56,0,0,0-4.56-4.56H51.84a5.7,5.7,0,0,0-5.7,5.7V297"
            />
            <line className="cls-1" x1="189.5" y1="248" x2="189.5" y2="297" />
            <line className="cls-1" x1="332.06" y1="248" x2="332.06" y2="297" />
            <circle className="cls-1" cx="44.31" cy="336.52" r="38.32" />
            <circle className="cls-1" cx="189.5" cy="336.52" r="38.32" />
            <circle className="cls-1" cx="332.06" cy="336.52" r="38.32" />
            <circle className="cls-1" cx="473.14" cy="336.52" r="38.32" />
            <line
                className="cls-1"
                x1="118.22"
                y1="280.92"
                x2="118.22"
                y2="422.04"
            />
            <line
                className="cls-1"
                x1="260.14"
                y1="280.92"
                x2="260.14"
                y2="422.04"
            />
            <line
                className="cls-4"
                x1="400.46"
                y1="280.92"
                x2="400.46"
                y2="422.04"
            />
            <rect
                className="cls-1"
                x="74.3"
                y="419.16"
                width="81.72"
                height="81.72"
                rx="5.76"
            />
            <rect
                className="cls-1"
                x="219.78"
                y="419.16"
                width="81.72"
                height="81.72"
                rx="6.82"
            />
            <rect
                className="cls-1"
                x="359.6"
                y="419.16"
                width="81.72"
                height="81.72"
                rx="6.39"
            />
        </SVG>
    );
};

export default category;
