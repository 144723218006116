import React from 'react';
import styled from 'styled-components';
// helpers
import svgHelper from '../../../assets/images/svg';

const staticColor = 'red';

const Container = styled.div`
    color: ${props => props.color || staticColor};
    display: grid;
    grid-template-columns: auto 35px;
    grid-row-gap: 2px;
    .bottom-line {
        width: 100%;
        height: 1px;
        background-color: ${props => props.underlineColor || staticColor};
        grid-column: span 2;
    }
    .left-aligned {
        justify-self: start;
        align-self: center;
    }
    .right-aligned {
        justify-self: end;
        align-self: center;
    }
    .area-1 {
        font-family: 'Nexa', sans-serif;
        font-weight: 700;
    }
    .area-2 {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .my-select{
        -webkit-appearance: none;
        -moz-appearance: none;
        color: ${props => props.color || staticColor};
        font-family: 'Roboto', sans-serif;
        border-width: 0px;
        border-color: transparent;
        background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='50' fill='${props =>
            props.dropColor}'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat right;
        background-size: 13px;
        opacity: 0.47;
        min-width: 204.8px;
        &:hover {
            opacity: 1;
        }
        &:focus {
            outline: none;
            opacity: 1;
        }
        & option {
            color: black;
        }
        & option:active {
            outline: none;
        }
    }
`;

const typeSelect = props => {
    const {
        className,
        color,
        width,
        underlineColor,
        label,
        svg,
        onChanged,
        value,
        dropColor,
        options,
    } = props;
    return (
        <Container
            className={className}
            dropColor={dropColor}
            color={color}
            underlineColor={underlineColor}
        >
            <div className="left-aligned area-1">{label}</div>
            <div className="right-aligned area-2">
                {svgHelper({
                    name: svg,
                    color: color,
                    width: width,
                })}
            </div>
            <select className="my-select" onChange={onChanged} value={value}>
                {options.map((oValue, index) => {
                    return (
                        <option
                            key={oValue}
                            value={oValue}
                            hidden={props.placeholder !== null && index === 0}
                        >
                            {oValue}
                        </option>
                    );
                })}
            </select>
            <div className="bottom-line area-5" />
        </Container>
    );
};

export default typeSelect;
