import React from 'react';

const contactComponent = props => {
    const { settings } = props;
    const { imgUrl, type, num } = settings;

    return (
        <div className="contact__container">
            <img
                className="contact__image"
                src={imgUrl}
                alt={type.toLowerCase()}
            />
            <div className="contact__text__container">
                <p className="contact__text__header">{type}</p>
                <p className="contact__text"> {num}</p>
            </div>
        </div>
    );
};

export default contactComponent;
