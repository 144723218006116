import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
// ui
import { Logo, NavBar, GMaps, Contacts, AboutDev } from '../../../ui';

const WrapperDiv = styled.div`
    background: black;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (min-width: 699px) {
        .home__header__main {
            width: 100%;
            max-width: 1300px;
            margin: auto;
            display: flex;
            align-items: center;
        }
        .home__header__main__1 {
            width: 50%;
        }
        .home__header__main__2 {
            width: 50%;
        }
        .home__header__sub {
            margin: auto;
        }
    }
    @media (min-width: 940px) {
        height: 45vh;
    }
`;

const logoCSS = css`
    width: 100%;
    justify-content: center;
    margin: 20px 0 10px 0;
    p {
        color: white;
        font-size: 1.25rem;
    }
    img {
        height: 3rem;
    }
    @media (min-width: 940px) {
        img {
            height: 3.5rem;
        }
        p {
            font-size: 1.5rem;
        }
    }
`;

const Address = styled.div`
    margin: 10px;
    font-size: 0.75rem;
    color: white;
    text-align: center;
    @media (min-width: 940px) {
        font-size: 0.9rem;
    }
`;

const gMapsCSS = css`
    margin: 10px auto;
    width: 90%;
    .gMap__map__wrapper {
        height: 100%;
    }
    .gMap__container__element {
        height: 200px;
    }
    @media (min-width: 699px) {
    }
`;

const contactsCSS = css`
    .contact__container {
        margin: 5px 10px;
    }
    .contact__text__header {
        font-size: 0.8rem;
        font-weight: 500;
    }
    .contact__text {
        font-size: 0.8rem;
    }
    @media (min-width: 940px) {
        .contact__text__header {
            font-size: 1rem;
        }
        .contact__text {
            font-size: 1rem;
        }
    }
`;

const activeClass = css`
    color: red;
`;

const navBarPropCSS = css`
    ul {
        flex-direction: row;
    }
    li {
        padding: 12px 5px;
    }
    a {
        font-weight: 400;
        font-size: 1rem;
        color: rgba(255, 255, 255, 0.6);
        padding: 2px 5px;
    }
    a:hover {
        color: #ff8000;
    }
    @media (min-width: 940px) {
        li {
            padding: 12px 5px;
        }
        a {
            font-size: 1.2rem;
            padding: 2px 5px;
        }
    }
`;

const aboutDevCSS = css`
    @media (min-width: 940px) {
        .copyright {
            font-size: 1rem;
        }
        .dev__container {
        }
        .dev__powered {
            font-size: 1rem;
            margin-right: 7px;
        }
        .dev__img {
            height: 1.5rem;
        }
    }
`;

const footer = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const updateWindowDimensions = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', updateWindowDimensions);
        return () => {
            window.removeEventListener('resize', updateWindowDimensions);
        };
    });

    let mainContainer = null;
    if (windowWidth < 699) {
        mainContainer = (
            <WrapperDiv>
                <Logo propCSS={logoCSS} />
                <Address>
                    No 6, Dinelto Traders, Kandy, Sri-Lanka, 60000
                </Address>
                <GMaps propCSS={gMapsCSS} zoomFactor={14} />
                <Contacts propCSS={contactsCSS} />
                <NavBar activeClass={activeClass} propCSS={navBarPropCSS} />
                <AboutDev propCSS={aboutDevCSS} />
            </WrapperDiv>
        );
    } else {
        mainContainer = (
            <WrapperDiv>
                <div className="home__header__main">
                    <div className="home__header__main__1">
                        <Logo propCSS={logoCSS} />
                        <Address>
                            No 6, Dinelto Traders, Kandy, Sri-Lanka, 60000
                        </Address>
                        <Contacts propCSS={contactsCSS} />
                    </div>
                    <div className="home__header__main__2">
                        <GMaps propCSS={gMapsCSS} zoomFactor={14} />
                    </div>
                </div>
                <div className="home__header__sub">
                    <NavBar activeClass={activeClass} propCSS={navBarPropCSS} />
                    <AboutDev propCSS={aboutDevCSS} />
                </div>
            </WrapperDiv>
        );
    }

    return <React.Fragment>{mainContainer}</React.Fragment>;
};

export default footer;
