import React from 'react';
import styled from 'styled-components';
// helpers
import svgHelper from '../../../assets/images/svg';

const staticColor = 'red';

const Container = styled.div`
    color: ${props => props.color || staticColor};
    display: grid;
    grid-template-columns: auto 35px;
    grid-row-gap: 2px;
    .left-aligned {
        justify-self: start;
        align-self: center;
    }
    .right-aligned {
        justify-self: end;
        align-self: center;
    }
    .area-1 {
        font-family: 'Nexa', sans-serif;
        font-weight: 700;
    }
    .area-2 {
        grid-row: span 2;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .my-input {
        -webkit-appearance: none;
        -moz-appearance: none;
        -moz-padding-start: 2.5px;
        border-width: 0px;
        color: ${props => props.color};
        font-family: 'Roboto', sans-serif;
        border-color: transparent;
        background: transparent;
        min-width: 167px;
        padding: 0;
        &:focus {
            outline: none;
            opacity: 1;
        }
    }
    .bottom-line {
        width: 100%;
        height: 1px;
        background-color: ${props => props.underlineColor || staticColor};
        grid-column: span 2;
    }
`;

const typeSelect = props => {
    const {
        className,
        color,
        width,
        underlineColor,
        label,
        svg,
        onChanged,
        value,
        placeholder,
    } = props;
    return (
        <Container
            className={className}
            color={color}
            labelHeight={width}
            underlineColor={underlineColor}
        >
            <div className="left-aligned area-1">{label}</div>
            <div className="right-aligned area-2">
                {svgHelper({
                    name: svg,
                    color: color,
                    width: width,
                })}
            </div>
            <input
                className="my-input"
                onChange={onChanged}
                value={value}
                placeholder={placeholder}
            />
            <div className="bottom-line area-5" />
        </Container>
    );
};

export default typeSelect;
