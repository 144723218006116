/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const WrapperNav = styled.nav`
    align-self: center;
    justify-content: center;
    ul {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        list-style: none;
    }
    li {
        display: inline-block;
        text-decoration: none;
    }
    ${props => props.propCSS}
`;

const NavLinkStyled = styled(NavLink)`
    text-decoration: none;
    font-weight: bolder;
    &.active {
        ${props => props.activeclass};
    }
`;

// no-noninteractive-element-interactions and click-events-have-key-events
const navLinks = props => {
    const { activeClass, propCSS, navClick, onKeyPressed } = props;
    return (
        <WrapperNav propCSS={propCSS}>
            <ul>
                <li onClick={navClick} onKeyPress={onKeyPressed}>
                    <NavLinkStyled
                        activeclass={activeClass}
                        activeClassName="active"
                        to="/"
                        exact
                    >
                        Home
                    </NavLinkStyled>
                </li>
                <li onClick={navClick} onKeyPress={onKeyPressed}>
                    {' '}
                    <NavLinkStyled
                        activeclass={activeClass}
                        activeClassName="active"
                        to="/shop"
                    >
                        Shop
                    </NavLinkStyled>
                </li>
                <li onClick={navClick} onKeyPress={onKeyPressed}>
                    {' '}
                    <NavLinkStyled
                        activeclass={activeClass}
                        activeClassName="active"
                        to="/about-us"
                    >
                        About Us
                    </NavLinkStyled>
                </li>
                <li onClick={navClick} onKeyPress={onKeyPressed}>
                    {' '}
                    <NavLinkStyled
                        activeclass={activeClass}
                        activeClassName="active"
                        to="/contact-us"
                    >
                        Contact Us
                    </NavLinkStyled>
                </li>
            </ul>
        </WrapperNav>
    );
};

export default navLinks;
