// @flow
import React, { Component } from 'react';
import { css } from 'styled-components';
// db
import items from '../../json_db/items';
// components
import Cover from '../../components/cover/Cover';
import Search from '../../components/search/Search';
import Promotions from '../../components/promotions/Promotions';
import Categories from '../../components/categories/categories';
import Services from '../../components/services/services';
import Brands from '../../components/brands/brands';
import Footer from '../../components/layout/footer/footer';
// constants
import { CATEGORY, BRAND, KEYWORD } from '../../global/svgList';

const maxWidth: string = '300px';

const searchPropCSS = css`
    display: none;
    margin: auto;
    width: 80%;
    max-width: 1300px;
    filter: drop-shadow(0px 0px 9px rgba(0, 0, 0, 0.3));
    -webkit-filter: drop-shadow(0px 0px 9px rgba(0, 0, 0, 0.3));
    background: #fafafb;
    position: relative;
    top: -1.8rem;
    z-index: 4;
    @media (min-width: 699px) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: center;
        .search-form-input-elements {
            width: 85%;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 20px;
            justify-content: space-between;
            align-content: center;
            padding: 10px 15px;
        }
        .search-select,
        .search-input {
            width: 100%;
            margin: 0;
            padding: 0;
            min-width: 0;
            max-width: ${maxWidth};
        }
        .my-select {
            min-width: 100px;
            max-width: 250px;
            grid-column: span 1;
            font-size: 0.8rem;
            background-size: 8px;
        }
        .my-input {
            min-width: 100px;
            max-width: 250px;
            grid-column: span 1;
            font-size: 0.8rem;
        }
        .area-1 {
            font-size: 1rem;
            padding: 3px 0px;
        }
        .area-2 {
            grid-row: span 3;
            align-self: flex-end;
        }
        .bottom-line {
            grid-column-start: 1;
            grid-column-end: 2;
            max-width: 250px;
        }
    }
    @media (min-width: 940px) {
        top: -3rem;
        .search-form-input-elements {
            padding: 25px 20px;
        }
        .search-select {
            margin: auto;
        }
        .search-input {
            margin: auto;
        }
        .my-select {
            font-size: 1rem;
            background-size: 13px;
        }
        .my-input {
            font-size: 1rem;
        }
        .area-1 {
            font-size: 1.4rem;
        }
        .area-2 svg {
            width: 35px;
        }
    }
    @media (min-width: 940px) and (max-width: 1382px) {
        .area-2 svg {
            padding-left: 7px;
        }
    }
`;

const svgSettings = {
    [CATEGORY]: {
        width: 25,
        color: 'black',
        dropColor: 'darkblue',
        underlineColor: '#518AFF',
    },
    [BRAND]: {
        width: 25,
        color: 'black',
        dropColor: 'darkblue',
        underlineColor: '#518AFF',
    },
    [KEYWORD]: {
        width: 25,
        color: 'black',
        underlineColor: '#518AFF',
    },
};

class Home extends Component<{}, { isActive: boolean }> {
    state = {
        isActive: false,
    };

    itemDbTest = () => {
        console.log(items[0]);
    };

    onClickListener = () => {
        const { isActive } = this.state;
        const activeness = isActive;
        this.setState({ isActive: !activeness });
    };

    render() {
        this.itemDbTest();
        return (
            <div>
                <Cover />
                <Search
                    buttonType="home-search"
                    propCSS={searchPropCSS}
                    svgSettings={svgSettings}
                    searchBarHeader={false}
                />
                <Promotions />
                <Categories />
                <Services />
                <Brands />
                <Footer />
            </div>
        );
    }
}

export default Home;
