import React, { Component } from 'react';
// import _ from 'lodash';
import styled from 'styled-components';
// constants
import { CAT, BRANDS } from '../../json_db';
// ui
import { Input } from '../../ui';
import searchElementsImport from './initSettings';
// icons
import searchICON from '../../assets/images/home/search.png';

const WrapperDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    ${props => props.propCSS};
`;

const Paragraph = styled.p`
    color: white;
    font-family: 'Nexa', sans-serif;
    font-weight: 700;
    font-size: 29px;
    text-align: center;
    margin-bottom: 2.5rem;
`;

const SearchNavButton = styled.div`
    margin-top: 1rem;
    text-align: center;
    min-width: 120px;
    max-width: 150px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-self: center;
    outline: none;
    background: white;
    border: none;
    vertical-align: middle;
    border-radius: 20px;
    font-family: 'Nexa', sans-serif;
    font-weight: 700;
    font-size: 25px;
    &:hover {
        background: linear-gradient(to right, #37bef1, #a53692);
    }
`;

const HomeButton = styled.div`
    background: #37bef1;
    width: 15%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Nexa', sans-serif;
    p {
        font-weight: 500;
        font-size: 1rem;
        color: white;
        margin: 0;
        padding: 0px 10px;
    }
    img {
        margin-top: 5px;
        width: 15px;
    }
    &:hover {
        background: linear-gradient(to right, #37bef1, #a53692);
    }
    @media (min-width: 940px) {
        p {
            font-size: 1.6rem;
        }
        img {
            width: 25px;
        }
    }
`;

class Search extends Component {
    state = {
        searchElements: searchElementsImport,
    };

    /**
     * Event Listeners for input elements
     */
    inputChangedHandler = (event, inputIdentifier) => {
        if (inputIdentifier === 'keyword' && event.target.value.length > 20) {
            return;
        }
        const { searchElements } = this.state;
        const updatedSearch = { ...searchElements };
        const updatedSearchElement = { ...updatedSearch[inputIdentifier] };
        updatedSearchElement.value = event.target.value;
        updatedSearchElement.valid =
            this.checkValidation(
                updatedSearchElement.value,
                updatedSearchElement.validation
            ) || event.target.value.length === 0;
        updatedSearch[inputIdentifier] = updatedSearchElement;
        this.setState({ searchElements: updatedSearch });
    };

    /**
     * Event Listeners for button
     */
    onClickHandler = () => {
        // const { searchElements } = this.state;
        // const k = _.map(searchElements, n => {
        //     return n.value;
        // });
        // alert(k.join('  '));
        alert('SEARCH OPTION WILL BE ADDED SOON');
    };

    bubblePreventer = event => {
        event.stopPropagation();
    };

    /**
     * Helper function to check validation if validation is needed by
     * input element settings initSettings
     */
    checkValidation = (value, validation) => {
        if (!validation.required) {
            return true;
        }
        return new RegExp(/^[A-Za-z\s]+$/).test(value);
    };

    getOptions = searchElement => {
        let value;
        if (searchElement.id === 'category') {
            value = CAT;
        } else if (searchElement.id === 'brand') {
            value = BRANDS;
        } else {
            return null;
        }
        return value;
    };

    render() {
        let form = null;
        const { searchElements } = this.state;
        const {
            svgSettings,
            propCSS,
            searchBarHeader,
            buttonType,
        } = this.props;
        const searchElementsArray = [];
        // eslint-disable-next-line no-unused-vars
        Object.keys(searchElements).map((key, index) => {
            searchElementsArray.push({
                id: key,
                config: searchElements[key],
            });
            return null;
        });
        form = searchElementsArray.map(searchElement => {
            const {
                label,
                elementType,
                placeholder,
                value,
                valid,
                svg,
                className,
            } = searchElement.config;
            return (
                <Input
                    className={className}
                    key={searchElement.id}
                    label={label}
                    elementType={elementType}
                    placeholder={placeholder}
                    value={value}
                    valid={valid}
                    onChanged={event =>
                        this.inputChangedHandler(event, searchElement.id)
                    }
                    svg={svg}
                    svgSettings={svgSettings[svg]}
                    options={this.getOptions(searchElement)}
                />
            );
        });
        return (
            <WrapperDiv
                propCSS={propCSS}
                onClick={event => this.bubblePreventer(event)}
            >
                {searchBarHeader ? (
                    <Paragraph>Search Your Product</Paragraph>
                ) : null}
                <div className="search-form-input-elements">{form}</div>
                {buttonType === 'mobilenav-search' ? (
                    <SearchNavButton
                        className="search-button"
                        onClick={this.onClickHandler}
                    >
                        SEARCH
                    </SearchNavButton>
                ) : (
                    <HomeButton onClick={this.onClickHandler}>
                        <p>SEARCH</p>
                        <img src={searchICON} alt="search" />
                    </HomeButton>
                )}
            </WrapperDiv>
        );
    }
}

export default Search;
