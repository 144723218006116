import React from 'react';
import css from './index.module.css';

const coverPic = () => {
    return (
        <div className={css.Cover}>
            <div className={css.CoverImageWrapper}>
                <div className={css.CoverImg} />
            </div>
            <div className={css.DarkFilter} />
            <div className={css.MainCover} />
            <div className={css.Ribbon} />
        </div>
    );
};

export default coverPic;
