import React from 'react';
import { BRAND, CATEGORY, KEYWORD, DROPDOWN } from '../../../global/svgList';
import Brand from './search/brand';
import Category from './search/categorys';
import Keyword from './search/keyword';
import Dropdown from './search/dropdown';

const svgHelper = props => {
    switch (props.name) {
        case BRAND:
            return <Brand {...props} />;
        case CATEGORY:
            return <Category {...props} />;
        case KEYWORD:
            return <Keyword {...props} />;
        case DROPDOWN:
            return <Dropdown {...props} />;
        default:
            throw new Error('SVG not found!!!');
    }
};

export default svgHelper;
