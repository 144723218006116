import React from 'react';

const smIcon = props => {
    const { href, icon, alt } = props;
    return (
        <a href={href}>
            <img src={icon} alt={alt} />
        </a>
    );
};

export default smIcon;
