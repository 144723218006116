import React from 'react';
import { compose } from 'recompose';
import { withScriptjs, withGoogleMap, GoogleMap } from 'react-google-maps';
import { MarkerWithLabel } from 'react-google-maps/lib/components/addons/MarkerWithLabel';

const mapComponent = compose(
    withScriptjs,
    withGoogleMap
)(props => {
    const { locationList, zoomFactor } = props;
    let locations = [];
    locations = locationList.map(value => {
        const { lat, long, title } = value;
        return (
            <MarkerWithLabel
                clickable
                key={title}
                position={{ lat: lat, lng: long }}
                labelAnchor={new window.google.maps.Point(0, 0)}
                labelStyle={{
                    background: 'linear-gradient(to right, #37BEF1, #A53692)',
                    color: 'white',
                    fontSize: '1rem',
                    padding: '3px 7px',
                    borderRadius: '13px',
                    border: '2px solid white',
                }}
            >
                <div>{title}</div>
            </MarkerWithLabel>
        );
    });

    const { lat, long } = props.locationList[0];

    return (
        <GoogleMap
            defaultZoom={zoomFactor || 16}
            defaultCenter={{ lat: lat, lng: long }}
        >
            {locations}
        </GoogleMap>
    );
});

export default mapComponent;
