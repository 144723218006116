import React from 'react';
import styled from 'styled-components';

const Category = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 38%;
    height: 38vw;
    max-width: 200px;
    max-height: 200px;
    padding: 10px;
    margin: 10px;
    text-align: center;
    background-color: #fdfdfd;
    filter: drop-shadow(6px 6px 6px rgba(0, 0, 0, 0.3));
    -webkit-filter: drop-shadow(6px 6px 6px rgba(0, 0, 0, 0.3));
    transition: all 0.2s ease-in-out;
    &:hover {
        transform: scale(1.05);
    }
    img {
        width: auto;
        height: 60%;
    }
    .cat-title {
        font-size: 14px;
        font-family: 'Lato', sans-serif;
        font-weight: 700;
        margin: 0px;
        margin-top: 10px;
    }
    .cat-desc {
        display: none;
    }
    @media (min-width: 699px) {
        margin: 0;
        width: 100%;
        height: 40vw;
        max-width: 350px;
        max-height: 350px;
        .cat-title {
            font-size: 1rem;
        }
        .cat-desc {
            display: inline-block;
            font-size: 0.8rem;
        }
        img {
            width: auto;
            height: 40%;
        }
    }
    @media (min-width: 940px) {
        .cat-title {
            font-size: 1.2rem;
        }
        .cat-desc {
            font-size: 1rem;
        }
    }
    @media (min-width: 1240px) {
        .cat-title {
            font-size: 1.5rem;
        }
        .cat-desc {
            font-size: 1.1rem;
        }
        img {
            width: auto;
            height: 40%;
        }
    }
`;

const category = props => {
    const { productCategory } = props;
    return (
        <Category>
            <img
                alt={productCategory.type}
                className="cat-img"
                src={productCategory.image}
            />
            <p className="cat-title">{productCategory.type}</p>
            <p className="cat-desc">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt
            </p>
        </Category>
    );
};

export default category;
