import React from 'react';
import styled, { css } from 'styled-components';
// ui
import { Logo, NavBar, SMBar } from '../../../../ui';

const WrapperDiv = styled.div`
    position: absolute;
    width: 100%;
    display: none;
    z-index: 3;
    top: 2vw;
    @media (min-width: 699px) {
        display: flex;
        justify-content: space-between;
    }
`;

const logoPropCSS = css`
    width: 16vw;
    padding-left: 2vw;
    p {
        color: white;
    }
    @media (min-width: 699px) {
        img {
            height: 1.75rem;
        }
        div p {
            font-size: 0.7rem;
        }
    }
    @media (min-width: 940px) {
        img {
            height: 2.5rem;
        }
        div p {
            font-size: 1rem;
        }
    }
`;

const activeClass = css`
    border: 4px solid white;
    border-radius: 30px;
`;

const navBarPropCSS = css`
    ul {
        flex-direction: row;
    }
    li {
        padding: 12px 5px;
    }
    a {
        color: rgb(255, 255, 255);
        padding: 2px 15px;
    }
    a:hover {
        color: #ff8000;
    }
    @media (min-width: 699px) {
        a {
            font-size: 1.1;
        }
    }
    @media (min-width: 940px) {
        a {
            font-size: 1.8rem;
        }
    }
`;

const sMBarPropCSS = css`
    width: 16vw;
    justify-content: flex-end;
    padding-right: 2vw;
    img {
        height: 2rem;
        padding-left: 10px;
    }
    img:hover {
        -webkit-filter: drop-shadow(0px 0px 5px #fff);
        filter: drop-shadow(0px 0px 5px #fff);
    }
    @media (min-width: 699px) {
        img {
            height: 1.4rem;
        }
    }
    @media (min-width: 940px) {
        img {
            height: 2rem;
        }
    }
`;

const mainHeader = props => {
    const { onHeaderNavClick } = props;
    return (
        <WrapperDiv>
            <Logo propCSS={logoPropCSS} />
            <NavBar
                activeClass={activeClass}
                propCSS={navBarPropCSS}
                navClick={onHeaderNavClick}
            />
            <SMBar propCSS={sMBarPropCSS} />
        </WrapperDiv>
    );
};

export default mainHeader;
