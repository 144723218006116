export const HOME_OFFICE_FURNITURE = 'Home and Office Furniture';
export const ELECTRONIC_HOME_APPLIANCES = 'Electronic and Home Appliances';
export const OFFICE_AUTOMATION = 'Office Automation';
export const POWER_TOOLS = 'Power Tools';
export const SPORT = 'Sport Items';
export const MUSICAL = 'Musical Instruments';

export const CAT = [
    HOME_OFFICE_FURNITURE,
    ELECTRONIC_HOME_APPLIANCES,
    OFFICE_AUTOMATION,
    POWER_TOOLS,
    SPORT,
    MUSICAL,
];
