import React from 'react';

// css
import classes from './header.module.css';

// components
import InfoHeader from './infoHeader/infoHeader';
import MainHeader from './mainHeader/mainHeader';

const header = () => {
    return (
        <div className={classes.Header}>
            <InfoHeader />
            <MainHeader />
        </div>
    );
};

export default header;
