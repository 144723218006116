import React from 'react';
import styled, { css } from 'styled-components';
// UI
import { NavBar, SMBar } from '../../ui';

const WrapperDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
`;

const activeClass = css`
    color: #37bef1;
`;

const navBarPropCSS = css`
    ul {
        flex-direction: column;
    }
    li {
        padding: 12px 15px;
    }
    a {
        color: rgb(255, 255, 255);
        font-size: 30px;
    }
    a:hover {
        color: #37bef1;
    }
`;

const sMBarPropCSS = css`
    position: absolute;
    width: 100%;
    justify-content: center;
    padding: 1vw;
    margin-bottom: 3vh;
    bottom: 0;
    img {
        height: 2.5rem;
        padding: 5px;
    }
    img:hover {
        -webkit-filter: drop-shadow(0px 0px 5px rgb(255, 255, 255));
        filter: drop-shadow(0px 0px 5px rgb(255, 255, 255));
    }
`;

const mobNav = props => {
    const { onMobNavClick } = props;
    return (
        <WrapperDiv>
            <NavBar
                activeClass={activeClass}
                propCSS={navBarPropCSS}
                navClick={onMobNavClick}
            />
            <SMBar propCSS={sMBarPropCSS} />
        </WrapperDiv>
    );
};

export default mobNav;
