import React from 'react';
import styled from 'styled-components';

const Backdrop = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    ${props => props.propCSS};
`;

const backDrop = props => {
    const { show, backdropClicked, propCSS, children } = props;
    return show ? (
        <Backdrop onClick={backdropClicked} propCSS={propCSS}>
            {children}
        </Backdrop>
    ) : null;
};

export default backDrop;
