import React from 'react';
import styled from 'styled-components';

const ServiceWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ecedee;
    margin: 10px 0px;
    min-height: 145px;
    @media (min-width: 699px) {
        /* border: 2px solid black; */
        max-width: 350px;
        flex-direction: column;
        margin: 50px 10px;
        width: 30%;
    }
    @media (min-width: 1240px) {
        margin: 50px 15px;
    }
`;

const Image = styled.img.attrs(props => ({
    src: props.image,
}))`
    height: 120px;
    width: auto;
    margin: 0 10px;
    @media (min-width: 699px) {
        height: 20vw;
        max-height: 200px;
    }
`;

const Text = styled.div`
    width: 45vw;
    margin: 0 10px;
    max-width: 300px;
    .service__main {
        font-weight: 700;
        font-size: 0.875rem;
        font-family: 'Lato', sans-serif;
    }
    .service__sub {
        font-size: 0.8rem;
    }
    @media (min-width: 699px) {
        width: 100%;
    }
    @media (min-width: 940px) {
        width: 100%;
        .service__main {
            font-size: 1.2rem;
        }
        .service__sub {
            font-size: 1rem;
        }
    }
    @media (min-width: 1280px) {
        width: 100%;
        .service__main {
            font-size: 1.5rem;
        }
        .service__sub {
            font-size: 1.1rem;
        }
    }
`;

const mobService = props => {
    const { image, heading, text, propCSS, toggled, cssClass } = props;
    return (
        <ServiceWrapper propCSS={propCSS} className={`service ${cssClass}`}>
            {toggled ? (
                <React.Fragment>
                    <Text>
                        <p className="service__main">{heading}</p>
                        <p className="service__sub">{text}</p>
                    </Text>
                    <Image image={image} />
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Image image={image} />
                    <Text>
                        <p className="service__main">{heading}</p>
                        <p className="service__sub">{text}</p>
                    </Text>
                </React.Fragment>
            )}
        </ServiceWrapper>
    );
};

export default mobService;
