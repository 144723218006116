import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
    .cls-1 {
        fill: none;
        stroke-width: ${props => props.stroke};
    }
    .cls-1,
    .cls-2 {
        stroke: ${props => props.color};
        stroke-miterlimit: 10;
    }
    .cls-2 {
        fill: ${props => props.color};
    }
`;

const brand = ({
    style = {},
    width = '100%',
    height = '100%',
    viewBox = '0 0 530 517',
    color = '#000',
    stroke = '14px',
}) => (
    <SVG
        color={color}
        style={style}
        width={width}
        height={height}
        viewBox={viewBox}
        stroke={stroke}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path
            className="cls-1"
            d="M99.23,172a1.78,1.78,0,0,1-1.78-1.77V61a16.05,16.05,0,0,1,16.11-16h52.33A17.05,17.05,0,0,1,183,62V170a2,2,0,0,1-2,2Z"
        />
        <line className="cls-1" x1="131" y1="71" x2="131" y2="131" />
        <path
            className="cls-1"
            d="M89.57,213H189a6,6,0,0,1,6,6V343l-37,30V295a2,2,0,0,0-2-2H124.5a4.49,4.49,0,0,0-4.5,4.5V373L86.5,339.5,84.11,218.57A5.46,5.46,0,0,1,89.57,213Z"
        />
        <line className="cls-1" x1="84.91" y1="259" x2="195" y2="259" />
        <path
            className="cls-1"
            d="M278.73,385H515a9,9,0,0,1,9,9V502.5a8.5,8.5,0,0,1-8.5,8.5H281.73a9.27,9.27,0,0,1-9.27-9.27V391.27A6.27,6.27,0,0,1,278.73,385Z"
        />
        <path
            className="cls-1"
            d="M12.5,417h260a0,0,0,0,1,0,0v81a0,0,0,0,1,0,0H12a6,6,0,0,1-6-6V423.5A6.5,6.5,0,0,1,12.5,417Z"
        />
        <line className="cls-1" x1="6" y1="457.5" x2="272.46" y2="457.5" />
        <line className="cls-1" x1="363" y1="385" x2="363" y2="425" />
        <line className="cls-1" x1="398.23" y1="385" x2="398.23" y2="425" />
        <line className="cls-1" x1="433" y1="385" x2="433" y2="425" />
        <line className="cls-1" x1="468" y1="385" x2="468" y2="425" />
        <line className="cls-1" x1="398.23" y1="474" x2="524" y2="474" />
        <path
            className="cls-1"
            d="M345,385V52A46,46,0,0,1,391,6h51.5A45.5,45.5,0,0,1,488,51.5V385"
        />
        <line className="cls-1" x1="120" y1="172" x2="120" y2="213" />
        <line className="cls-1" x1="158" y1="172" x2="158" y2="213" />
        <line className="cls-1" x1="183" y1="59" x2="345" y2="59" />
        <line className="cls-1" x1="183" y1="128.5" x2="345" y2="128.5" />
        <circle className="cls-1" cx="414.75" cy="63.25" r="27.25" />
        <ellipse className="cls-2" cx="353.5" cy="476.25" rx="14.5" ry="8.75" />
        <ellipse
            className="cls-2"
            cx="397.45"
            cy="474.08"
            rx="5.71"
            ry="6.11"
        />
        <circle className="cls-2" cx="363" cy="423.24" r="6.24" />
        <circle className="cls-2" cx="398.23" cy="423.24" r="6.24" />
        <circle className="cls-2" cx="433.12" cy="423.24" r="6.24" />
        <circle className="cls-2" cx="468" cy="423.24" r="6.24" />
    </SVG>
);

export default brand;
