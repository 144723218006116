import { ELECTRONIC_HOME_APPLIANCES } from './cat';
import { RADIO } from './subcat';
import { SONY } from './brands';
import decimalFormatter from './helpers';

const items = [
    {
        cat: ELECTRONIC_HOME_APPLIANCES,
        subCat: RADIO,
        brand: SONY,
        price: decimalFormatter(1500),
        details: {
            Voltage: '240V',
            Wattage: '1500W',
            Guarantee: '5 Years',
        },
        imageURL:
            'https://firebasestorage.googleapis.com/v0/b/dinelto.appspot.com/o/dinelto%2Felectronic-and-home-appliances%2Ffridge.JPG?alt=media&token=d3e05e4b-eed4-47e7-9eaa-22d4658baced',
    },
];

export default items;
