// @flow
import React from 'react';
import Slider from 'react-slick';
// $FlowFixMe
import styled from 'styled-components';
// icons
import abans from '../../assets/images/brand/abans.png';
import jvc from '../../assets/images/brand/jvc.png';
import lg from '../../assets/images/brand/lg.png';
import philips from '../../assets/images/brand/philips.png';
import samsung from '../../assets/images/brand/samsung.png';
import singer from '../../assets/images/brand/singer.png';
import sisil from '../../assets/images/brand/sisil.png';
import arrowNext from '../../assets/images/brand/right-arrow.png';
import arrowPrev from '../../assets/images/brand/left-arrow.png';

type IconArr = {
    name: string,
    image: string,
};

const iconsArr: Array<IconArr> = [
    { name: 'abans', image: abans },
    { name: 'jav', image: jvc },
    { name: 'lg', image: lg },
    { name: 'philips', image: philips },
    { name: 'samsung', image: samsung },
    { name: 'singer', image: singer },
    { name: 'sisil', image: sisil },
];

const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    responsive: [
        {
            breakpoint: 1480,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 1280,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            },
        },
    ],
};

const MainContainer = styled.div`
    width: 100%;
    margin: 40px 0;
    @media (min-width: 940px) {
        margin: 60px 0;
    }
    @media (min-width: 1240px) {
        margin: 80px 0;
    }
`;

const StyledSlider = styled(Slider)`
    width: 80%;
    height: auto;
    margin: auto;
    .slick-track {
        display: flex;
        align-items: center;
    }
    .slick-slide {
        height: auto;
    }
    .slick-arrow {
    }
    .slick-prev {
        background: url(${arrowPrev});
        background-size: cover;
        background-repeat: no-repeat;
    }
    .slick-next {
        background: url(${arrowNext});
        background-size: cover;
        background-repeat: no-repeat;
    }
    .slick-next:before,
    .slick-prev:before {
        font-size: 0px;
        opacity: 1;
    }
    @media (min-width: 699px) {
        .slick-prev {
            height: 30px;
            width: 30px;
        }
        .slick-next {
            height: 30px;
            width: 30px;
        }
    }
    @media (min-width: 1240px) {
        .slick-prev {
            height: 40px;
            width: 40px;
        }
        .slick-next {
            height: 40px;
            width: 40px;
        }
    }
`;

const ImageContainer = styled.div`
    .brand-image {
        margin: auto;
        width: 20vw;
    }
    @media (min-width: 600px) {
        .brand-image {
            width: 150px;
        }
    }
`;

const brands = () => {
    const list = iconsArr.map(element => (
        <ImageContainer key={element.name}>
            <img
                src={element.image}
                alt={element.name}
                className="brand-image"
            />
        </ImageContainer>
    ));

    return (
        <MainContainer>
            <StyledSlider {...settings}>{list}</StyledSlider>
        </MainContainer>
    );
};

export default brands;
