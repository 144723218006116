import React from 'react';

// css
import css from './infoHeader.module.css';

// images
import inqICON from '../../../../assets/images/infoHeader/support.png';

const inquiry = () => (
    <div className={css.Inquiry}>
        <img src={inqICON} alt="Contact Us" />
        <p>Inquiry : 0777123456</p>
    </div>
);

export default inquiry;
