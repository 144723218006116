import React from 'react';
import styled from 'styled-components';
// components
import SmIcon from './smIcon';
// icons
import fbICON from '../../../assets/images/sm/facebook.png';
import youtubeICON from '../../../assets/images/sm/youtube.png';
// URLs
const facebook = 'https://www.facebook.com/';
const youtube = 'https://www.youtube.com/';

const WrapperDiv = styled.div`
    display: flex;
    align-items: center;
    a {
        padding: 0;
        margin: 0;
        vertical-align: middle;
        text-decoration: none;
    }
    img {
        vertical-align: middle;
    }
    ${props => props.propCSS}
`;

const socialMedia = props => {
    const { propCSS } = props;
    return (
        <WrapperDiv propCSS={propCSS}>
            <SmIcon href={facebook} icon={fbICON} alt="facebook" />
            <SmIcon href={youtube} icon={youtubeICON} alt="youtube" />
        </WrapperDiv>
    );
};

export default socialMedia;
