import React from 'react';
import styled from 'styled-components';

const Hamburger = styled.div`
    display: inline-block;
    transition-property: opacity, filter;
    transition-duration: 5s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    outline: none;
`;

const HamburgerBox = styled.div`
    width: 2rem;
    height: 1.5rem;
    display: inline-block;
    position: relative;
`;

const HamburgerInner = styled.div`
    display: block;
    top: 2px;

    &,
    &::before,
    &::after {
        width: 2rem;
        height: 4px;
        background-color: #000;
        border-radius: 4px;
        position: absolute;
        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;
        opacity: 1;
    }

    &::before,
    &::after {
        content: '';
    }

    &::before {
        top: -10px;
        top: 10px;
    }

    &::after {
        bottom: -10px;
        top: 20px;
    }

    &.is-active,
    &.is-active::before,
    &.is-active::after {
        opacity: 1;
        background: white;
    }

    &::before {
        transition-property: transform, opacity;
        transition-timing-function: ease;
        transition-duration: 0.3s;
    }

    &.is-active {
        transform: translate3d(0, 10px, 0) rotate(45deg);
    }

    &.is-active::before {
        transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
        opacity: 0;
    }

    &.is-active::after {
        transform: translate3d(0, -20px, 0) rotate(-90deg);
    }
`;

const hamburger = props => {
    const { isActive, buttonClick, onKeyPressHandler } = props;

    // double check event handlers, remove role and onKeyPress and check errors
    return (
        <Hamburger
            className={
                isActive
                    ? 'Hamburger Hamburger--slider is-active '
                    : 'Hamburger Hamburger--slider'
            }
            role="button"
            onKeyPress={onKeyPressHandler}
            onClick={buttonClick}
            tabIndex={0}
        >
            <HamburgerBox>
                <HamburgerInner className={isActive ? 'is-active' : null} />
            </HamburgerBox>
        </Hamburger>
    );
};

export default hamburger;
