import React from 'react';
import styled from 'styled-components';
import MapComponent from './mapComponent';
import apiKey from '../../../global/constants';

type LocationArrayObject = {
    lat: number,
    long: number,
    title: string,
};

const locationArray: Array<LocationArrayObject> = [
    {
        lat: 7.3738438,
        long: 80.5208803,
        title: 'Dinelto Traders',
    },
    // {
    //     lat: 7.3769241,
    //     long: 80.5212773,
    //     title: 'Dinelto Traders Main Office',
    // },
];

const WrapperDiv = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    .gMap__map__wrapper {
        height: 400px;
        border-radius: 0px;
    }
    .gMap__container__element {
        height: 40vh;
    }
    ${props => props.propCSS}
`;

const locationGMaps = props => {
    const { zoomFactor, propCSS } = props;
    return (
        <WrapperDiv propCSS={propCSS[0]}>
            <MapComponent
                zoomFactor={zoomFactor}
                locationList={locationArray}
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${apiKey}&v=3.exp&libraries=geometry,drawing,places`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div className="gMap__container__element" />}
                mapElement={<div className="gMap__map__wrapper" />}
            />
        </WrapperDiv>
    );
};

export default locationGMaps;
