import React from 'react';
import styled from 'styled-components';
// images
import logoICON from '../../assets/images/logo/logo.png';

const WrapperDiv = styled.div.attrs(props => ({
    className: props.propClass,
}))`
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
        vertical-align: middle;
        height: 2.5rem;
    }
    div {
        display: flex;
        flex-direction: column;
        padding-left: 0.5rem;
    }
    p {
        margin: 0;
        padding: 0.1rem;
        font-family: 'Titillium Web';
        font-weight: 500;
        line-height: 100%;
    }
    ${props => props.propCSS}
`;

const logo = props => {
    const { propCSS } = props;
    return (
        <WrapperDiv propCSS={propCSS}>
            <img src={logoICON} alt="Dinelto Traders" />
            <div>
                <p>DINELTO</p>
                <p>TRADERS</p>
            </div>
        </WrapperDiv>
    );
};

export default logo;
