import React from 'react';
// module imports
import { Route, Switch } from 'react-router-dom';
// containers
import Home from './containers/Home/Home';
import Shop from './containers/Shop/Shop';
import ContactUs from './containers/ContactUs/ContactUs';
import AboutUs from './containers/AboutUs/AboutUs';
// components
import Layout from './components/layout/layout';

const App = () => (
    <Layout>
        <Switch>
            <Route path="/shop" component={Shop} />
            <Route path="/contact-us" component={ContactUs} />
            <Route path="/about-us" component={AboutUs} />
            <Route path="/" exact component={Home} />
        </Switch>
    </Layout>
);

// class App extends Component {
//     render() {
//         return (
//             <Layout>
//                 <Switch>
//                     <Route path="/shop" component={Shop} />
//                     <Route path="/services" component={Services} />
//                     <Route path="/about-us" component={AboutUs} />
//                     <Route path="/" exact component={Home} />
//                 </Switch>
//             </Layout>
//         );
//     }
// }

export default App;
