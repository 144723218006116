import React, { Component } from 'react';
import styled, { css, keyframes } from 'styled-components';
import cloneDeep from 'lodash/cloneDeep';
// ui
import { TextSlayer } from '../../ui';
// images
import promoImg1 from '../../assets/images/promo/pic1.jpg';
import promoImg2 from '../../assets/images/promo/pic2.jpg';
import promoImg3 from '../../assets/images/promo/pic3.jpg';
import promoImg4 from '../../assets/images/promo/pic4.jpg';
import promoImg5 from '../../assets/images/promo/pic5.jpg';
import promoImg6 from '../../assets/images/promo/pic6.jpg';
import promoImg7 from '../../assets/images/promo/pic7.jpg';
// colors
import {
    SECONDARY_YELLOW,
    MAIN_BLUE,
    MAIN_PINK,
} from '../../global/themeColors';

/*
Breakpoints
    |- 699
    |- 940
    |- 1240 
*/

// Container which contains all of the elements related to Promotion Component
const MainContainer = styled.div`
    width: 100%;
    text-align: center;
    margin-top: 20px;
    .cat-topic {
        display: inline-block;
        margin: auto;
        p {
            font-family: 'Titillium Web', sans-serif;
            font-weight: 700;
            font-size: 0.9rem;
            margin: 0;
        }
        div {
            height: 2px;
            width: 90%;
            margin: auto;
            background-color: #a5365c;
        }
    }
    @media (min-width: 699px) {
        margin-top: -10px;
    }
    @media (min-width: 940px) {
        margin-top: -20px;
        .cat-topic {
            p {
                font-size: 1.4rem;
            }
            div {
                height: 4px;
            }
        }
    }
    @media (min-width: 1240px) {
        .cat-topic {
            p {
                font-size: 1.8rem;
            }
        }
    }
`;

// Provides a container for promotion container and sets background color
const WrapperDiv = styled.div`
    padding: 8px 0;
    width: 100%;
    margin: 10px auto 0px auto;
    background: #fafafa;
    @media (min-width: 940px) {
        margin-top: 15px;
    }
`;

// Contains 3 promotion components
const PromotionContainer = styled.div`
    width: 92%;
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 7px;
    grid-row-gap: 7px;
    margin: auto;
    .promotion-1 {
        height: 40vw;
        grid-column: span 2;
        grid-row: span 2;
        background: url(${props => props.imageArray[0]});
        background-size: cover;
        background-repeat: no-repeat;
        border: 2px solid ${SECONDARY_YELLOW};
    }
    .promotion-2 {
        position: relative;
        background: url(${props => props.imageArray[1]});
        background-size: cover;
        border: 2px solid ${MAIN_BLUE};
    }
    .promotion-3 {
        position: relative;
        background: url(${props => props.imageArray[2]});
        background-size: cover;
        border: 2px solid ${MAIN_PINK};
    }
    .promotion-1,
    .promotion-2,
    .promotion-3 {
        filter: drop-shadow(0px 0px 9px rgba(0, 0, 0, 0.3));
        -webkit-filter: drop-shadow(0px 0px 9px rgba(0, 0, 0, 0.3));
    }
    .fadeIn {
        animation: ${props => props.fadeInAnimation};
        -webkit-animation-timing-function: ease;
        animation-timing-function: ease;
    }
    .fadeOut {
        animation: ${props => props.fadeOutAnimation};
        -webkit-animation-timing-function: ease;
        animation-timing-function: ease;
    }
    @media (min-width: 699px) {
        width: 90%;
        grid-column-gap: 15px;
        grid-row-gap: 15px;
        .promotion-1 {
            height: 38vw;
        }
        .promotion-1,
        .promotion-2,
        .promotion-3 {
            border-radius: 10px;
        }
        .promotion-1 {
            border-width: 4px;
        }
        .promotion-2 {
            border-width: 4px;
        }
        .promotion-3 {
            border-width: 4px;
        }
    }
`;

// Animation set for big promo div
const fadeInAnimation = css`
    ${keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }`} 2s
`;

const fadeOutAnimation = css`
    ${keyframes`
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }`} 1s
`;

// CSS related to text slayer displayed in this component
const textSlayerPropCSS = css`
    position: absolute;
    z-index: 3;
    bottom: 2%;
    left: 2%;
    p {
        padding: 0px;
        margin: 2px;
        background: none;
    }
    /* promotion box 1 css */
    .promo1-slayer-0 {
        color: white;
        padding: 0 5px;
        background-color: rgba(0, 0, 0, 0.5);
        font-weight: 700;
        font-size: 1.2rem;
    }
    .promo1-slayer-1,
    .promo1-slayer-2 {
        color: white;
        font-size: 0.8rem;
    }
    /* promotion box 2 css */
    .promo2-slayer-0,
    .promo2-slayer-1,
    .promo2-slayer-2 {
        display: none;
    }
    /* promotion box 3 css */
    .promo3-slayer-0,
    .promo3-slayer-1,
    .promo3-slayer-2 {
        display: none;
    }
    @media (min-width: 699px) {
        /* promotion box 1 css */
        .promo1-slayer-0 {
            font-size: 2rem;
        }
        .promo1-slayer-1,
        .promo1-slayer-2 {
            font-size: 1.2rem;
        }
        /* promotion box 2 css */
        .promo2-slayer-0 {
            display: inline-block;
            font-size: 1.4rem;
        }
        .promo2-slayer-1,
        .promo2-slayer-2 {
            display: inline-block;
            font-size: 0.8rem;
        }
        /* promotion box 3 css */
        .promo3-slayer-0 {
            display: inline-block;
            font-size: 1.4rem;
        }
        .promo3-slayer-1,
        .promo3-slayer-2 {
            display: inline-block;
            font-size: 0.8rem;
        }
    }
    @media (min-width: 940px) {
        /* promotion box 1 css */
        .promo1-slayer-0 {
            font-size: 2.5rem;
        }
        .promo1-slayer-1,
        .promo1-slayer-2 {
            font-size: 1.5rem;
        }
        /* promotion box 2 css */
        .promo2-slayer-0 {
            font-size: 1.5rem;
        }
        .promo2-slayer-1,
        .promo2-slayer-2 {
            font-size: 1rem;
        }
        /* promotion box 3 css */
        .promo3-slayer-0 {
            font-size: 1.5rem;
        }
        .promo3-slayer-1,
        .promo3-slayer-2 {
            font-size: 1rem;
        }
    }
    @media (min-width: 1440px) {
        /* promotion box 1 css */
        .promo1-slayer-0 {
            font-size: 3.5rem;
        }
        .promo1-slayer-1,
        .promo1-slayer-2 {
            font-size: 2rem;
        }
        /* promotion box 2 css */
        .promo2-slayer-0 {
            font-size: 2rem;
        }
        .promo2-slayer-1,
        .promo2-slayer-2 {
            font-size: 1.4rem;
        }
        /* promotion box 3 css */
        .promo3-slayer-0 {
            font-size: 2rem;
        }
        .promo3-slayer-1,
        .promo3-slayer-2 {
            font-size: 1.4rem;
        }
    }
`;

// Animation passed to text slayer component which gives animation in initial load
const textSlayerAnimation = css`
    ${keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }`} 3s
`;

const allPromotionsArray = [
    {
        pic: promoImg1,
        text: ['50% Off', 'Apple McBook Pro 2019', '3 Units available'],
        mainText: [true, ' rgba(0, 0, 0, 0.5)', 'white'],
        secondaryText: [],
    },
    {
        pic: promoImg2,
        text: ['20% Off', 'Nike Air', 'Christmas Offer'],
    },
    {
        pic: promoImg3,
        text: ['Free Data Package', '5GB worth Dialog data', 'For 3 Months'],
    },
    {
        pic: promoImg4,
        text: [
            '5000 LKR Off',
            'With every sampath credit card',
            'Limited Offer',
        ],
    },
    {
        pic: promoImg5,
        text: [
            '32GB Micro SD',
            'With every Sony DSLR',
            'Ultrafast Sandisk SD Card',
        ],
    },
    {
        pic: promoImg6,
        text: ['5% Off', 'JBL EarGone P1000', 'Limited Offer'],
    },
    {
        pic: promoImg7,
        text: [
            '..to Your Office',
            'For every online purchase',
            'Free Transport',
        ],
    },
];

class Promotions extends Component {
    constructor(props) {
        super(props);
        this.promRef = React.createRef();
    }

    state = {
        imgArr: [promoImg1, promoImg2, promoImg3],
        promoArr: allPromotionsArray,
    };

    componentDidMount() {
        this.timer(10000);
        this.addAnimationTimer(9050);
    }

    componentWillUpdate() {
        this.timer(10000);
        this.addAnimationTimer(9050);
    }

    componentWillUnmount() {
        clearInterval(this.timerId);
        clearInterval(this.timerIdAdd);
    }

    // Iterates the arrays for cycle animation using state
    timer = interval => {
        this.timerId = setTimeout(() => {
            const { promoArr } = this.state;
            this.promRef.current.classList.add('fadeIn');
            this.promRef.current.classList.remove('fadeOut');
            const newImgArr = cloneDeep(promoArr);
            const firstObj = newImgArr.shift();
            newImgArr.push(firstObj);
            const newDisplayImages = [];
            for (let i = 0; i < 3; i += 1) {
                newDisplayImages.push(newImgArr[i].pic);
            }
            this.setState({
                imgArr: newDisplayImages,
                promoArr: newImgArr,
            });
        }, interval);
    };

    /*
     * Adds and removes animation related classes to Prom 1 using intervals
     * set at DidMount and WillUpdate
     */
    addAnimationTimer = interval => {
        this.timerIdAdd = setTimeout(() => {
            this.promRef.current.classList.add('fadeOut');
            this.promRef.current.classList.remove('fadeIn');
        }, interval);
    };

    render() {
        const { imgArr, promoArr } = this.state;
        return (
            <MainContainer>
                <div className="cat-topic">
                    <p>PROMOTIONS</p>
                    <div />
                </div>
                <WrapperDiv>
                    <PromotionContainer
                        fadeInAnimation={fadeInAnimation}
                        fadeOutAnimation={fadeOutAnimation}
                        imageArray={imgArr}
                    >
                        <div ref={this.promRef} className="promotion-1">
                            <TextSlayer
                                classPrefix="promo1-slayer"
                                textLines={promoArr[0].text}
                                animation={textSlayerAnimation}
                                propCSS={textSlayerPropCSS}
                            />
                        </div>
                        <div className="promotion-2">
                            <TextSlayer
                                classPrefix="promo2-slayer"
                                textLines={promoArr[1].text}
                                animation={textSlayerAnimation}
                                propCSS={textSlayerPropCSS}
                            />
                        </div>
                        <div className="promotion-3">
                            <TextSlayer
                                classPrefix="promo3-slayer"
                                textLines={promoArr[2].text}
                                animation={textSlayerAnimation}
                                propCSS={textSlayerPropCSS}
                            />
                        </div>
                    </PromotionContainer>
                </WrapperDiv>
            </MainContainer>
        );
    }
}

export default Promotions;
