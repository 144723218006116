import React from 'react';
// ui
import Input from './typeInput';
import Select from './typeSelect';

const input = props => {
    let inputElement = null;
    const { svgSettings } = props;
    const newSvgSettings = { ...svgSettings };

    switch (props.elementType) {
        case 'input':
            if (!props.valid) {
                newSvgSettings.color = 'red';
                newSvgSettings.underlineColor = 'red';
            } else {
                newSvgSettings.color = props.svgSettings.color;
                newSvgSettings.underlineColor =
                    props.svgSettings.underlineColor;
            }
            inputElement = (
                <Input
                    label={props.label}
                    className={`search-input ${props.className}`}
                    onChanged={props.onChanged}
                    value={props.value}
                    svg={props.svg}
                    {...newSvgSettings}
                    placeholder={props.placeholder}
                />
            );
            break;
        case 'select': {
            const { options } = props;
            const optionsArr = [...options];
            if (props.placeholder !== null) {
                optionsArr.unshift(props.placeholder);
            }
            inputElement = (
                <Select
                    label={props.label}
                    className={`search-select ${props.className}`}
                    onChanged={props.onChanged}
                    value={props.value}
                    svg={props.svg}
                    {...newSvgSettings}
                    options={optionsArr}
                />
            );
            break;
        }
        default:
            inputElement = (
                <input onChange={props.onChanged} value={props.value} />
            );
    }
    return <div>{inputElement}</div>;
};

export default input;
