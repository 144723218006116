import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
    .cls-1 {
        fill: ${props => props.color};
        stroke: ${props => props.color};
        stroke-miterlimit: 10;
    }
`;

const dropdown = ({
    style = {},
    width = '100%',
    height = '100%',
    viewBox = '0 0 21.19 12.81',
    color = '#000',
}) => (
    <SVG
        color={color}
        style={style}
        width={width}
        height={height}
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <polygon
            className="cls-1"
            points="6.45 0.5 20.13 0.5 10.6 12.02 1.06 0.5 6.45 0.5"
        />
    </SVG>
);

export default dropdown;
