import React from 'react';
import { css } from 'styled-components';
// components
import Search from '../search/Search';
// constants
import { CATEGORY, BRAND, KEYWORD } from '../../global/svgList';

const searchPropCSS = css`
    .search-select,
    .search-input {
        width: 80%;
        margin: auto;
        padding: 0px 15px;
        margin-top: 3px;
        margin-bottom: 30px;
        min-width: 200px;
        max-width: 500px;
    }
    .my-select {
        grid-column: span 2;
        font-size: 0.875rem;
    }
    .my-input {
        font-size: 0.875rem;
    }
    .area-1 {
        font-size: 22px;
        padding: 7px 0;
    }
`;

const svgSettings = {
    [CATEGORY]: {
        width: 35,
        color: 'white',
        underlineColor: 'white',
        dropColor: 'white',
    },
    [BRAND]: {
        width: 35,
        color: 'white',
        underlineColor: 'white',
        dropColor: 'white',
    },
    [KEYWORD]: { width: 35, color: 'white', underlineColor: 'white' },
};

const mobSearch = () => (
    <Search
        buttonType="mobilenav-search"
        propCSS={searchPropCSS}
        searchBarHeader
        svgSettings={svgSettings}
    />
);

export default mobSearch;
