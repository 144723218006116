import React from 'react';
import styled from 'styled-components';

const WrapperDiv = styled.div`
    animation: ${props => props.animation};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    ${props => props.propCSS};
`;

const Paragraph = styled.p`
    background-color: rgba(0, 0, 0, 0.52);
    margin: 0.4rem 0;
    padding: 0.2rem 0.4rem;
    font-size: 1rem;
    color: white;
`;

const textSlayer = props => {
    const { animation, propCSS, textLines, classPrefix } = props;
    return (
        <WrapperDiv animation={animation} propCSS={propCSS}>
            {textLines.map((text, index) => (
                <Paragraph
                    key={text}
                    className={`${classPrefix || 'textslayer'}-${index}`}
                >
                    {text}
                </Paragraph>
            ))}
        </WrapperDiv>
    );
};

export default textSlayer;
