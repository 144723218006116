import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    background: linear-gradient(45deg, #ffca55, #ed3338);
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .coming-soon {
        margin: 0;
        padding: 0;
        font-size: 3rem;
        color: black;
        font-style: italic;
        font-weight: 700;
    }
`;

const AboutUs = () => {
    return (
        <Container>
            <h1 className="coming-soon">Coming Soon...</h1>
        </Container>
    );
};

export default AboutUs;
