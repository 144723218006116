import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
    .cls-1 {
        fill: none;
        stroke-width: ${props => props.stroke};
    }
    .cls-1,
    .cls-2,
    .cls-3 {
        stroke: ${props => props.color};
        stroke-miterlimit: 10;
    }
    .cls-2,
    .cls-3 {
        fill: ${props => props.color};
    }
    .cls-2 {
        stroke-width: 9px;
    }
    .cls-3 {
        stroke-width: 5px;
    }
`;

const keyword = ({
    style = {},
    width = '100%',
    height = '100%',
    viewBox = '0 0 427.4 454.76',
    color = '#000',
    stroke = '17px',
}) => {
    return (
        <SVG
            color={color}
            style={style}
            width={width}
            height={height}
            viewBox={viewBox}
            stroke={stroke}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path
                className="cls-1"
                d="M110,258.83l-91.9-93.1a33.28,33.28,0,0,1-9.37-27L19.23,44a22.14,22.14,0,0,1,18.9-19.52L151.46,8.7a21.36,21.36,0,0,1,17.45,5.5L414.12,241.33a15,15,0,0,1,.21,21.72L282.57,389.92a21.17,21.17,0,0,1-29,.36l-78.63-72.13"
            />
            <circle className="cls-1" cx="73.3" cy="70.42" r="21.6" />
            <circle className="cls-1" cx="203.08" cy="192.28" r="46.98" />
            <path
                className="cls-1"
                d="M11.67,159l-3.1,41.21a22.86,22.86,0,0,0,7.18,18.48l240.09,222.9a17.38,17.38,0,0,0,23.72,0L412.79,318.33a19.3,19.3,0,0,0,1.5-26.61l-10.52-12.26"
            />
            <circle className="cls-2" cx="139.45" cy="284.71" r="7.11" />
            <circle className="cls-3" cx="109.79" cy="258.57" r="5.99" />
            <circle className="cls-3" cx="172.43" cy="315.94" r="5.99" />
        </SVG>
    );
};

export default keyword;
