import React from 'react';
import styled from 'styled-components';
// images
import searchICON from '../../assets/images/home/search.png';

const Button = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    padding: 3px 7px;
    border: 3px solid rgb(255, 255, 255);
    border-radius: 20px;
    background-color: #22282a;
    box-shadow: 6px 6px 6px 2px rgba(0, 0, 0, 0.16);
    ${props => props.propCSS};
    p {
        color: white;
        margin: 0;
        padding: 0;
        padding-right: 0.5rem;
        font-size: 0.8rem;
        font-family: 'Roboto';
        font-weight: 500;
        vertical-align: middle;
    }
    img {
        height: 0.7rem;
    }
    &:hover {
        background: linear-gradient(to right, #37bef1, #a53692);
    }
`;

const search = props => {
    const { searchClick, propCSS } = props;
    return (
        <Button onClick={searchClick} propCSS={propCSS}>
            <p>SEARCH</p>
            <img src={searchICON} alt="search" />
        </Button>
    );
};

export default search;
