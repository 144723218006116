import React from 'react';
import styled, { css, keyframes } from 'styled-components';
// ui
import { Logo, TextSlayer, CoverPicture } from '../../ui';
// const CoverPicture = React.lazy(() => import('../../ui/home/coverPic/coverPic'));

const WrapperDiv = styled.div`
    position: relative;
`;

const textSlayerTexts = ['All Home/Office appliances', 'Under one roof'];

const textSlayerAnimation = css`
    ${keyframes`
        from {
            left: -275px;
        }
        to {
            left: 2vw;
        }`}
    1s
`;

const logoPropCSS = css`
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    justify-content: center;
    top: 10%;
    z-index: 3;
    p {
        color: white;
    }
    @media (min-width: 699px) {
        display: none;
    }
`;

const textSlayerPropCSS = css`
    position: absolute;
    z-index: 3;
    top: 40%;
    left: 2vw;
    @media (min-width: 699px) {
        p {
            font-size: 1rem;
        }
    }
    @media (min-width: 940px) {
        p {
            font-size: 1.6rem;
        }
    }
`;

const cover = () => (
    <WrapperDiv>
        <TextSlayer
            classPrefix="cover-slayer"
            textLines={textSlayerTexts}
            animation={textSlayerAnimation}
            propCSS={textSlayerPropCSS}
        />
        <Logo propCSS={logoPropCSS} />
        <CoverPicture />
        {/* <Suspense fallback={FallBack}><CoverPicture /></Suspense> */}
    </WrapperDiv>
);

export default cover;
