import React from 'react';
import styled from 'styled-components';
import devICON from '../../assets/images/dev/lxy.png';

const WrapperDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px 0 20px 0;
    color: white;
    .copyright {
        text-align: center;
        font-size: 0.8rem;
    }
    .dev__container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
    }
    .dev__powered {
        font-size: 0.8rem;
        margin-right: 5px;
    }
    .dev__img {
        height: 1.2rem;
    }
    ${props => props.propCSS}
`;

const aboutDev = props => {
    const { propCSS } = props;
    return (
        <WrapperDiv propCSS={propCSS}>
            <div className="copyright">
                © Copyright 2019 Dinelto Traders All rights reserved
            </div>
            <div className="dev__container">
                <div className="dev__powered">Powered by : </div>
                <img className="dev__img" src={devICON} alt="logicxy" />
            </div>
        </WrapperDiv>
    );
};

export default aboutDev;
