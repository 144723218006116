import React, { useState, useEffect } from 'react';
import { css } from 'styled-components';
// components
import Header from './header/header';
import MobNav from '../mobile/mobNav';
import MobSearch from '../mobile/mobSearch';
// css
import CSS from './layout.module.css';
// ui
import { Hamburger, SearchButton, Backdrop } from '../../ui';

const NAV = 'mobile_nav';
const SEARCH = 'mobile_search';

const searchButtonPropCSS = css`
    display: none;
`;

const backdropPropCSS = css`
    background-color: rgba(0, 0, 0, 0.8);
`;

const layout = props => {
    const [isActive, setIsActive] = useState(false);
    const [showDrop, setShow] = useState(false);
    const [component, setComponent] = useState('');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const updateWindowDimensions = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', updateWindowDimensions);
        return () => {
            window.removeEventListener('resize', updateWindowDimensions);
        };
    });

    const onClickListener = () => {
        setIsActive(!isActive);
        setShow(!showDrop);
        setComponent(NAV);
    };

    const onSearchClickListener = () => {
        setIsActive(!isActive);
        setShow(!showDrop);
        setComponent(SEARCH);
    };

    const { children } = props;

    const mobileUIGenerator = () => {
        let mobUi;
        if (component === NAV) {
            mobUi = <MobNav onMobNavClick={() => onClickListener()} />;
        } else if (component === SEARCH) {
            mobUi = <MobSearch />;
        } else {
            mobUi = null;
        }
        return mobUi;
    };

    if (windowWidth > 699) {
        if (isActive !== false && showDrop !== false) {
            setIsActive(false);
            setShow(false);
        }
    }

    return (
        <div>
            <div
                className={
                    showDrop
                        ? [CSS.MobileButtons, CSS.DropActive].join(' ')
                        : CSS.MobileButtons
                }
            >
                <Hamburger
                    propCSS={
                        showDrop
                            ? [CSS.LayoutHamburger, CSS.LayoutHamburgerMain]
                            : null
                    }
                    isActive={isActive}
                    buttonClick={() => onClickListener()}
                />
                <SearchButton
                    propCSS={showDrop ? searchButtonPropCSS : null}
                    searchClick={() => onSearchClickListener()}
                />
            </div>
            {showDrop ? (
                <Backdrop
                    propCSS={backdropPropCSS}
                    show={showDrop}
                    backdropClicked={() => onClickListener()}
                >
                    {mobileUIGenerator()}
                </Backdrop>
            ) : null}
            <div
                style={{ overflow: 'hidden' }}
                className={showDrop ? CSS.Content : null}
            >
                <Header />
                {children}
            </div>
        </div>
    );
};

export default layout;
