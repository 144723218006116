import React from 'react';
import styled from 'styled-components';
// component
import ContactComponent from './contact';
import callICON from '../../../assets/images/contact/call.png';
import emailICON from '../../../assets/images/contact/email.png';
import faxICON from '../../../assets/images/contact/printer.png';
// settings
const settings = {
    any: {
        imgUrl: callICON,
        type: 'ANY INQUIRY',
        num: '0000-000-000',
    },
    fax: {
        imgUrl: faxICON,
        type: 'FAX',
        num: '0000-000-000',
    },
    email: {
        imgUrl: emailICON,
        type: 'EMAIL',
        num: 'dinelto@gmail.com',
    },
};

const WrapperDiv = styled.div`
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    p {
        color: white;
        margin: 0;
        padding: 0.1rem;
        line-height: 100%;
    }
    .contact__container {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .contact__image {
        vertical-align: middle;
        height: 2rem;
    }
    .contact__text__container {
        display: flex;
        flex-direction: column;
        padding-left: 0.5rem;
    }
    ${props => props.propCSS}
`;

const contactBox = props => {
    const { propCSS } = props;
    const { any, fax, email } = settings;
    return (
        <WrapperDiv propCSS={propCSS}>
            <ContactComponent settings={any} />
            <ContactComponent settings={fax} />
            <ContactComponent settings={email} />
        </WrapperDiv>
    );
};

export default contactBox;
